import React, { useEffect, Suspense } from 'react';
import { HashRouter, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import './scss/style.scss';
 
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);



// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const ForgotPassword = React.lazy(() => import('./views/pages/forgot-password/forgot-password'));
const PrivateRoutes = React.lazy(() => import('./PrivateRoutes'));

const App = () => {
  const isAuthetcate = localStorage.getItem('token'); 
  const isPassword = localStorage.getItem('password'); 
  const navigate = useNavigate();




  useEffect(() => {
    const currentTheme = localStorage.getItem('themeis') || 'light';
    document.body.className = currentTheme === 'dark' ? 'dark-theme' : 'light-theme';
  }, []);
  return (
      <Suspense fallback={loading}>
          <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/404" element={<Page404 />} />
        <Route path="/500" element={<Page500 />} />
        <Route element={<PrivateRoutes />}>
          <Route path="*" element={<DefaultLayout />} />
            </Route>
          </Routes>
        </Suspense>
  );
};

const MainApp = () => (
  <HashRouter>
    <App />
      </HashRouter>
);

export default MainApp;
