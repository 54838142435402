import {createStore,applyMiddleware,combineReducers} from 'redux'

import thunk from 'redux-thunk';
import reducers from './reducers/index'; 
import departmentReducer from './reducers/departmentReducer'

const rootReducer = combineReducers({
    ...reducers,
    departments: departmentReducer
  });
  const store = createStore(rootReducer, {}, applyMiddleware(thunk, /* other middlewares if needed */), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());


export default store;