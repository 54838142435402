import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { createRoot } from 'react-dom/client'
import App from './App';
import { ToastContainer } from "react-toastify";
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './redux/store'



createRoot(document.getElementById('root')).render(
  <>
    <Provider store={store}>
      <App />
    </Provider>
    <ToastContainer style={{ zIndex: "1029" , marginTop:"60px" }} />
  </>
)

reportWebVitals()
