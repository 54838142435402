import {
  ADD_DEPARTMENT_SUCCESS,
  ADD_DEPARTMENT_FAILURE,
  // ... other action types
} from '../actions/departmentAction';

const initialState = {
  departments: [], // Initial value for departments
  // ... other properties
};

const departmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departments: [action.payload.department_name],
      };
    case ADD_DEPARTMENT_FAILURE:
      return state;
    // ... other cases
    default:
      return state;
  }
};

export default departmentReducer;
